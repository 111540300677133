/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable test-selectors/onChange */
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';

import {
	Folder as FolderIcon,
	Search as SearchIcon,
	Server,
	WifiOff as WifiOffIcon,
} from '@ctlyst.id/internal-icon';
import {
	Box,
	BoxProps,
	ChakraTable as Table,
	Flex,
	Loader as Spinner,
	TableBodyProps,
	TableCellProps,
	TableColumnHeaderProps,
	TableHeadProps,
	TableProps,
	TableRowProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@ctlyst.id/internal-ui';
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	OnChangeFn,
	RowSelectionInstance,
	RowSelectionState,
	SortingState,
	Updater,
	useReactTable,
} from '@tanstack/react-table';

import IndeterminateCheckbox from './indeterminate-checkbox';

export interface TableStyleProps {
	table?: TableProps;
	tableHead?: TableHeadProps;
	tableRow?: TableRowProps;
	tableBody?: TableBodyProps;
	tableColumnHeader?: TableColumnHeaderProps;
	tableCell?: TableCellProps;
}

export interface DataTableProps<T = unknown> extends BoxProps {
	styles?: TableStyleProps;
	columns: ColumnDef<T>[];
	dataSource: T[] | undefined;
	isLoading?: boolean;
	withSelectedRow?: boolean;
	onSelectedRow?: (data: T[]) => void;
	onSort?: (data: SortingState) => void;
	sortDescFirst?: boolean;
	isEmptyByFilter?: boolean;
	isEmptyBySearch?: boolean;
	isServerDown?: boolean;
	isEmpty?: boolean;
	emptyText?: string;
	defaultSortingState?: SortingState;
	testId?: string;
	headerSticky?: boolean;
	onRowClick?: (data: T) => void;
}

export type DataTableRefs<T> = Pick<
	RowSelectionInstance<T>,
	'toggleAllRowsSelected'
>;

const DataTable = forwardRef(
	<T extends unknown>(
		props: DataTableProps<T>,
		ref?: React.Ref<DataTableRefs<T>>
	) => {
		const {
			columns,
			dataSource = [],
			isLoading,
			withSelectedRow,
			onSelectedRow,
			onSort,
			sortDescFirst,
			styles,
			isEmptyByFilter,
			isEmptyBySearch,
			isEmpty,
			isServerDown,
			emptyText,
			defaultSortingState,
			testId,
			headerSticky,
			onRowClick,
		} = props;

		const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
		const [sorting, setSorting] = useState<SortingState>(
			defaultSortingState ?? []
		);
		const [rowSelection, onRowSelectionChange] = useState<RowSelectionState>(
			{}
		);
		const [isLostConnection, setIsLostConnection] = useState(false);

		const dataColumns = useMemo<ColumnDef<T>[]>(() => columns, [columns]);
		const checkboxColumn = useMemo<ColumnDef<T>[]>(
			() => [
				{
					id: 'select',
					size: 32,
					header: ({ table }) => (
						<IndeterminateCheckbox
							name="select-header"
							{...{
								isChecked: table.getIsAllRowsSelected(),
								isIndeterminate: table.getIsSomeRowsSelected(),
								onChange: table.getToggleAllRowsSelectedHandler(),
							}}
						/>
					),
					cell: ({ row }) => (
						<IndeterminateCheckbox
							name={`select-${row.index}`}
							{...{
								isChecked: row.getIsSelected(),
								isIndeterminate: row.getIsSomeSelected(),
								onChange: row.getToggleSelectedHandler(),
								'': '',
							}}
						/>
					),
				},
			],
			[]
		);

		const generateColumn = () => {
			if (withSelectedRow) {
				return [...checkboxColumn, ...dataColumns];
			}
			return dataColumns;
		};

		const onSortingChange: OnChangeFn<SortingState> = (
			data: Updater<SortingState>
		) => {
			setSorting(data);
		};

		const table = useReactTable({
			data: dataSource,
			columns: generateColumn(),
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			manualPagination: true,
			manualSorting: true,
			sortDescFirst: sortDescFirst,
			state: {
				sorting,
				rowSelection,
			},
			onRowSelectionChange,
			onSortingChange,
		});

		const { getSelectedRowModel, toggleAllRowsSelected } = table;
		const { flatRows } = getSelectedRowModel();

		useImperativeHandle(ref, () => ({
			toggleAllRowsSelected,
		}));

		useEffect(() => {
			const rowData = flatRows.map(row => row.original) || [];
			if (onSelectedRow) {
				onSelectedRow(rowData);
			}
		}, [JSON.stringify(flatRows)]);

		useEffect(() => {
			window.addEventListener('online', () => {
				setIsLostConnection(false);
			});
			window.addEventListener('offline', () => {
				setIsLostConnection(true);
			});

			return () => {
				window.removeEventListener('online', () => {
					setIsLostConnection(false);
				});
				window.removeEventListener('offline', () => {
					setIsLostConnection(true);
				});
			};
		}, []);

		useEffect(() => {
			if (onSort && !isFirstLoad) {
				onSort(sorting);
			}
		}, [sorting]);

		useEffect(() => {
			setIsFirstLoad(false);
		}, []);

		if (isServerDown) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding={20}
					flexDirection="column"
				>
					<Box color="neutral.500">
						<Server size={110} color="neutral.500" />
					</Box>
					<Box
						mt={4}
						color="gray.600"
						maxW={52}
						textAlign="center"
						data-test-id="CT_component_data-table_isServerDown"
					>
						Server sedang down. Silakan coba sesaat lagi.
					</Box>
				</Box>
			);
		}

		if (isLostConnection) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding={20}
					flexDirection="column"
				>
					<Box color="neutral.500">
						<WifiOffIcon size={110} color="neutral.500" />
					</Box>
					<Box
						mt={4}
						color="gray.600"
						maxW={52}
						textAlign="center"
						data-test-id="CT_component_data-table_isLostConnection"
					>
						Koneksimu terputus. Silakan coba sesaat lagi.
					</Box>
				</Box>
			);
		}

		if (isEmptyBySearch) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding={20}
					flexDirection="column"
				>
					<Box color="neutral.500">
						<SearchIcon size={110} color="neutral.500" />
					</Box>
					<Box
						mt={4}
						color="gray.600"
						maxW={280}
						textAlign="center"
						data-test-id="CT_component_data-table_isEmptyBySearch"
					>
						Keyword yang dicari tidak ditemukan. Coba cari keyword lainnya.
					</Box>
				</Box>
			);
		}

		if (isEmptyByFilter) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding={20}
					flexDirection="column"
				>
					<Box color="neutral.500">
						<SearchIcon size={110} color="neutral.500" />
					</Box>
					<Box
						mt={4}
						color="gray.600"
						maxW={250}
						textAlign="center"
						data-test-id="CT_component_data-table_isEmptyByFilter"
					>
						Data yang dicari tidak ditemukan. Coba ubah pengaturan filter.
					</Box>
				</Box>
			);
		}

		if (isEmpty) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding={20}
					flexDirection="column"
				>
					<Box color="neutral.500">
						<FolderIcon size={110} color="neutral.500" />
					</Box>
					<Box
						mt={4}
						color="gray.600"
						maxW={80}
						textAlign="center"
						data-test-id="CT_component_data-table_isEmpty"
					>
						{emptyText || 'Belum ada data'}
					</Box>
				</Box>
			);
		}

		return (
			<Table
				data-test-id={`CT_component_data-table_${testId || ''}`}
				{...styles?.table}
			>
				{isLoading && (
					<Flex
						w="100%"
						h="100%"
						pos="absolute"
						top={0}
						left={0}
						bg="white"
						align="center"
						justify="center"
						zIndex={2}
					>
						<Spinner color="primary.500" size="lg" />
					</Flex>
				)}
				<Thead
					{...(styles?.tableHead,
					headerSticky
						? { position: 'sticky', top: 0, bg: 'white', zIndex: 1 }
						: {})}
				>
					{table.getHeaderGroups().map(headerGroup => (
						<Tr
							mx="2"
							key={headerGroup.id}
							{...styles?.tableRow}
							_hover={{
								bg: 'rgb(249, 250, 251)',
							}}
						>
							{headerGroup.headers.map((header, index) => (
								<Th
									key={header.id}
									colSpan={header.colSpan}
									width={`${
										header.getSize() +
										(index === 0 || index === headerGroup.headers.length - 1
											? 16
											: 0)
									}px`}
									{...styles?.tableColumnHeader}
								>
									<Flex
										data-test-id={`CT_component_data-table_${
											header.column.getCanSort() ? 'pointer' : 'default'
										}_${testId || ''}`}
										textTransform="capitalize"
										align="center"
										userSelect="none"
										onClick={header.column.getToggleSortingHandler()}
										cursor={header.column.getCanSort() ? 'pointer' : 'default'}
										gap={2}
									>
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{header.column.getCanSort() && (
											<Box
												as="span"
												data-test-id={`CT_component_data-table_short_${
													header.column.id
												}_${header.column.getIsSorted() || 'default'}`}
											>
												{{
													asc: '↑',
													desc: '↓',
												}[header.column.getIsSorted() as string] ?? '↕'}
											</Box>
										)}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>

				<Tbody {...styles?.tableBody}>
					{table.getRowModel().rows.map(row => (
						<Tr
							mx="2"
							key={row.id}
							data-test-id={`CT_component_data-table_${testId || ''}_row-${
								row.id
							}`}
							{...styles?.tableRow}
							_hover={{
								bg: 'rgb(249, 250, 251)',
							}}
							onClick={() => {
								if (onRowClick) {
									onRowClick(row.original);
								}
							}}
						>
							{row.getVisibleCells().map(cell => (
								<Td
									key={cell.id}
									data-test-id={`CT_component_data-table_${testId || ''}_cell-${
										cell.id
									}`}
									textStyle="text.md"
									width={cell.column.getSize()}
									{...styles?.tableCell}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		);
	}
);

DataTable.displayName = 'DataTable';

export default DataTable as <T extends unknown>(
	props: DataTableProps<T> & {
		ref?: React.Ref<DataTableRefs<T>>;
	}
) => JSX.Element;
