/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

import * as Icon from '@ctlyst.id/internal-icon';
import {
	Box,
	Button,
	Flex,
	Link,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Text,
	useColorModeValue,
} from '@ctlyst.id/internal-ui';
import { Menu, SubMenu, useGetMenuQuery } from '@services/menu';

const mappingIcon = new Map([
	['Order', <Icon.ShoppingBag size={4} key="order" color="inherit" />],
	[
		'Fulfillment',
		<Icon.ShoppingCart size={4} key="fullfilment" color="inherit" />,
	],
	[
		'Transfer Stock',
		<Icon.Repeat size={4} key="transfer-stock" color="inherit" />,
	],
	['Stock', <Icon.Box size={4} key="stock" color="inherit" />],
	[
		'Product Database',
		<Icon.Layers size={4} key="product-database" color="inherit" />,
	],
	['Purchasing', <Icon.DollarSign size={4} key="purchasing" color="inherit" />],
	['Reseller', <Icon.Users size={4} key="reseller" color="inherit" />],
	['Retur', <Icon.RotateCcw size={4} key="retur" color="inherit" />],
	['Other', <Icon.Settings size={4} key="other" color="inherit" />],
	['Content', <Icon.Layout size={4} key="content" color="inherit" />],
	['Customer', <Icon.HelpCircle size={4} key="content" color="inherit" />],
	['Voucher', <Icon.Gift size={4} key="content" color="inherit" />],
]);

const Navigation: React.FC = () => {
	const { data, isFetched } = useGetMenuQuery();
	const { pathname, asPath } = useRouter();

	const urlMenu = (subMenu: SubMenu) => {
		if (window.location.hostname === 'localhost') {
			return subMenu.navLink;
		}
		return subMenu.navHost + subMenu.navLink;
	};

	return (
		<Box
			px="6"
			pt="4"
			hidden={isFetched && data === undefined}
			backgroundRepeat="repeat-x"
			data-test-id="CT_component_navigation_cms"
		>
			<Flex
				bg="white"
				w="full"
				rounded={'md'}
				flexWrap="wrap"
				alignItems="center"
				shadow="raised"
				gap={1}
				px={4}
				py={2}
			>
				{data?.map((item: Menu, i: React.Key | null | undefined) => {
					const childMenu = item.children.filter(subMenu =>
						asPath.includes(subMenu.navLink)
					);

					const isActive = pathname.startsWith(childMenu[0]?.navLink);
					const activeBg = isActive ? 'primary.500' : undefined;

					return (
						<Popover
							isLazy
							key={i}
							trigger="hover"
							placement="bottom-start"
							openDelay={100}
							closeDelay={100}
						>
							{({ isOpen, onClose }) => (
								<>
									<PopoverTrigger>
										<Button
											h={7.5}
											p={2}
											size="sm"
											variant="ghost"
											data-test-id={`CT_component_navigation_button-${
												item.title
											}${isActive ? '-active' : ''}`}
											_hover={{
												backgroundColor: !isActive
													? useColorModeValue('neutral.400', 'transparent')
													: activeBg,
											}}
											backgroundColor={
												isOpen && !isActive
													? useColorModeValue('neutral.400', 'transparent')
													: activeBg
											}
											color={isActive ? 'primary.50' : 'black.high'}
											leftIcon={mappingIcon.get(item.title)}
											rightIcon={<Icon.ChevronDown size={4} color="inherit" />}
										>
											{item.title}
										</Button>
									</PopoverTrigger>

									{item.children && (
										<PopoverContent
											border="none"
											boxShadow="base"
											py={1}
											width={240}
										>
											{item.children.map((subMenu: SubMenu) => (
												<NextLink
													key={subMenu.id}
													passHref
													href={urlMenu(subMenu)}
												>
													<Link
														key={subMenu.id}
														display="flex"
														alignItems="center"
														transition="padding 0.35s ease 0s"
														_hover={{
															pl: 6,
														}}
														_first={{ borderTopRadius: 'md' }}
														_last={{ borderBottomRadius: 'md' }}
														py={3}
														px={4}
														color={
															pathname.startsWith(subMenu.navLink)
																? 'primary.800'
																: 'black.high'
														}
														onClick={onClose}
														background={
															pathname.startsWith(subMenu.navLink)
																? useColorModeValue('primary.50', 'transparent')
																: 'transparent'
														}
														data-test-id={`CT_component_navigation_link-${item.id}`}
													>
														{mappingIcon.get(item.title)}
														<Text textStyle="text-sm" ml={3}>
															{subMenu.title}
														</Text>
													</Link>
												</NextLink>
											))}
										</PopoverContent>
									)}
								</>
							)}
						</Popover>
					);
				})}
			</Flex>
		</Box>
	);
};

export default Navigation;
