import { useMemo } from 'react';
import {
	components as ComponentRS,
	GroupHeadingProps,
	OptionProps,
} from 'react-select';

import { Checkbox, Flex, Text } from '@ctlyst.id/internal-ui';

import SelectWithCheckboxBase, {
	CHECKBOX_STATE,
	getSelectAllCheckboxState,
	OptionMultiGroup,
	OptionsGroup,
	SelectWithCheckboxBaseProps,
} from './base-select-checkbox';

export const optionMultiGroupTranform = <Option,>(
	options: OptionsGroup<Option>
): OptionsGroup<Option> => {
	const _options = options.map(item => {
		return {
			label: item.label,
			options: [
				{
					label: `Pilih Semua ${item.label}`,
					value: `ALL-${item.label}`,
					selectAllCheckbox: true,
					group: item.options[0].group,
				},
				...item.options,
			],
		};
	}) as OptionsGroup<Option>;
	return _options;
};

const InputGroupOption = <Option, IsMulti extends boolean>({
	getStyles,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	data,
	checkedState,
	isMulti,
	...rest
}: OptionProps<OptionMultiGroup<Option>, IsMulti> & {
	checkedState: number;
}) => {
	const style = {
		alignItems: 'center',
		color: 'inherit',
		display: 'flex ',
	};

	// prop assignment
	const props: JSX.IntrinsicElements['div'] = {
		...innerProps,
		onClick: e => {
			if (innerProps.onClick) {
				innerProps.onClick(e);
			}
		},
		style,
	};

	return (
		<>
			<ComponentRS.Option
				data={data}
				isMulti={true}
				isDisabled={isDisabled}
				isFocused={isFocused}
				isSelected={isSelected}
				getStyles={getStyles}
				innerProps={props}
				{...rest}
			>
				<Flex
					alignItems="center"
					width="100%"
					mb="3px"
					gap={3}
					data-test-id="CT_component_multigroup-select-checkbox_options"
				>
					{data?.selectAllCheckbox ? (
						<Checkbox
							isChecked={checkedState === CHECKBOX_STATE.CHECKED}
							isIndeterminate={checkedState === CHECKBOX_STATE.INDETERMINATE}
							data-test-id="CT_component_multigroup-select-checkbox_select-all-option"
						/>
					) : (
						<Checkbox
							isChecked={isSelected}
							mr="8px"
							data-test-id="CT_component_multigroup-select-checkbox_option-checkbox"
						/>
					)}
					<Text
						textStyle={'text.sm'}
						data-test-id={`multigroup-select-checkbox-option-label-${data.value}`}
					>
						{children}
					</Text>
				</Flex>
			</ComponentRS.Option>
		</>
	);
};

const MultiGroupHeading = <T,>(props: GroupHeadingProps<T>) => {
	return (
		<>
			<hr />
			<Flex alignItems="center" p={3}>
				<Text color={'black.high'} textStyle="text.sm" fontWeight={'semibold'}>
					<ComponentRS.GroupHeading {...props} />
				</Text>
			</Flex>
			<hr />
		</>
	);
};

const remainingMultiGroupOption = <Option,>(
	options: OptionsGroup<Option>,
	selectedOptions: OptionMultiGroup<Option>[] | undefined
): Record<string, { remaining: number; total: number }> => {
	const _remainingObj = options.map(r_item => {
		const objOption: Record<string, typeof r_item.options[0]> = {};
		r_item.options.forEach(option => {
			objOption[option.value.toString()] = option;
		});
		return {
			label: r_item.label,
			total: r_item.options.length, // all item checkbox not included
			group: r_item.options[0].group,
			options: objOption,
		};
	});
	selectedOptions?.forEach(item => {
		// iterate find and delete option that has been selected
		_remainingObj.forEach(itemRemaining => {
			const selectedIndex = itemRemaining.options[item.value.toString()];
			if (!!selectedIndex) {
				delete itemRemaining.options[item.value.toString()];
				// using some and return true to do immediate exit when selected option is found in remaining data
				return true;
			}
			return false;
		});
	});
	const remainingTotal: Record<string, { remaining: number; total: number }> =
		{};
	_remainingObj.forEach(item => {
		remainingTotal[`${item.group}`] = {
			remaining: Object.keys(item.options).length - 1, // select all checkbox not included
			total: item.total - 1, // select all checkbox not included
		};
	});
	return remainingTotal;
};

const MultiGroupSelectCheckbox = <Option,>(
	props: SelectWithCheckboxBaseProps<OptionMultiGroup<Option>> & {
		handleCheckAll: (group: string, value: number) => void;
	}
) => {
	const { handleCheckAll, ...restProps } = props;
	const optionsGroup = props.options as OptionsGroup<Option>;
	const value = props.value as OptionMultiGroup<Option>[] | undefined;

	const remaining = useMemo(
		() => remainingMultiGroupOption(optionsGroup, value),
		[optionsGroup, value]
	);

	const getGroupChecked = (option: OptionMultiGroup<Option>) => {
		const groupState = remaining[option.group];
		return getSelectAllCheckboxState(
			groupState.total - groupState.remaining,
			groupState.total
		);
	};
	return (
		<SelectWithCheckboxBase
			{...restProps}
			isMulti
			components={{
				GroupHeading: MultiGroupHeading,
				Option: ({ innerProps, ...restOption }) => {
					const checkedState = restOption.data.selectAllCheckbox
						? getGroupChecked(restOption.data)
						: -1; // -1 for item that is a SelectAllCheckbox
					return (
						<InputGroupOption
							{...restOption}
							checkedState={checkedState}
							innerProps={{
								...innerProps,
								onClick: ev => {
									if (innerProps.onClick) innerProps.onClick(ev);
									if (restOption.data?.selectAllCheckbox && checkedState >= 0) {
										handleCheckAll(restOption.data.group, checkedState);
									}
								},
							}}
							data={restOption.data}
						/>
					);
				},
			}}
		></SelectWithCheckboxBase>
	);
};

export default MultiGroupSelectCheckbox;
