import * as React from 'react';

import { SVGIconProps } from './types';

export const VoucherIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		data-test-id="CT_component_voucher-icon_cms"
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		{...rest}
	>
		<path
			d="M22.3501 9.53C22.9801 9.29 23.4301 8.72 23.4301 8.05V6.5C23.4301 5.12 22.3101 4 20.9301 4H3.07007C1.69007 4 0.570068 5.12 0.570068 6.5V8.04C0.570068 8.72 1.02007 9.28 1.65007 9.52C2.69007 9.93 3.43007 10.88 3.43007 12C3.43007 13.12 2.70007 14.07 1.65007 14.47C1.02007 14.71 0.570068 15.28 0.570068 15.95V17.49C0.570068 18.88 1.69007 20 3.07007 20H20.9201C22.3001 20 23.4201 18.88 23.4201 17.5V15.96C23.4201 15.28 22.9701 14.72 22.3401 14.48C21.3001 14.07 20.5701 13.12 20.5701 12C20.5701 10.88 21.3001 9.93 22.3501 9.53ZM21.4301 16.25V17.49C21.4301 17.77 21.2001 17.99 20.9301 17.99H3.07007C2.80007 18 2.57007 17.77 2.57007 17.5V16.26C4.31007 15.5 5.43007 13.85 5.43007 12C5.43007 10.15 4.31007 8.5 2.57007 7.75V6.5C2.57007 6.23 2.80007 6 3.07007 6H20.9201C21.2001 6 21.4201 6.23 21.4201 6.5V7.74C19.6801 8.5 18.5701 10.15 18.5701 12C18.5701 13.85 19.6801 15.5 21.4301 16.25Z"
			fill="#707070"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.7153 6.67748V4.90331H15.7153V6.67748C15.7153 7.22976 15.2676 7.67748 14.7153 7.67748C14.163 7.67748 13.7153 7.22976 13.7153 6.67748ZM14.7153 9.22583C15.2676 9.22583 15.7153 9.67354 15.7153 10.2258V13.7742C15.7153 14.3265 15.2676 14.7742 14.7153 14.7742C14.163 14.7742 13.7153 14.3265 13.7153 13.7742V10.2258C13.7153 9.67354 14.163 9.22583 14.7153 9.22583ZM14.7153 16.3225C15.2676 16.3225 15.7153 16.7702 15.7153 17.3225V19.0967H13.7153V17.3225C13.7153 16.7702 14.163 16.3225 14.7153 16.3225Z"
			fill="#707070"
		/>
	</svg>
);
