import { useState } from 'react';
import { createRoot } from 'react-dom/client';

import {
	ChakraProvider,
	ModalHeaderProps,
	theme,
	useDisclosure,
} from '@ctlyst.id/internal-ui';

import { Modal as ModalConfirmation } from '@components';

export interface ConfirmSetting {
	title?: string;
	titleStyles?: ModalHeaderProps;
	content?: string;
	actionText?: string;
	secondaryActionText?: string;
	testId?: string;
	variant?: string | 'default' | 'danger';
}
export const useConfirm = () => {
	const [status, setStatus] = useState<'waiting' | 'confirm' | undefined>(
		undefined
	);
	const isWaitingConfirmation = status === 'waiting';
	const modalConfirmationDisclosure = useDisclosure();
	const confirmDialog = ({
		title = 'Buang Perubahan',
		titleStyles = {},
		content = 'Jika keluar sekarang, perubahan yang telah dibuat tidak akan tersimpan.',
		actionText = 'Buang Perubahan',
		secondaryActionText = 'Kembali',
		testId = 'dialog-confirmation',
		variant = 'danger',
	}: ConfirmSetting = {}) => {
		const dialogExist = document.querySelector('#dialog-container');
		const modalDiv = document.createElement('div');
		modalDiv.setAttribute('id', 'dialog-container');
		const root = createRoot(modalDiv);
		document.body.append(modalDiv);
		const variants: { [key: string]: string } = {
			default: 'primary',
			danger: 'red',
		};
		return new Promise<boolean>(resolve => {
			const onClose = (confirm: boolean) => {
				if (confirm) {
					setStatus('confirm');
				} else {
					setStatus(undefined);
				}
				root.unmount();
				resolve(confirm);
				modalConfirmationDisclosure.onClose();
				modalDiv.remove();
			};
			if (dialogExist) {
				root.unmount();
				resolve(false);
				modalConfirmationDisclosure.onClose();
				modalDiv.remove();
			}
			root.render(
				<ChakraProvider theme={theme}>
					<ModalConfirmation
						isOpen={true}
						showCloseButton={false}
						header={title}
						headerStyles={titleStyles}
						colorScheme={variants[variant] || 'red'}
						onClose={() => onClose(false)}
						onAction={() => onClose(true)}
						onSecondaryAction={() => onClose(false)}
						actionText={actionText}
						secondaryActionText={secondaryActionText}
						testId={testId}
						isCentered
						bodyStyles={{
							color: 'black.high',
						}}
					>
						{content}
					</ModalConfirmation>
				</ChakraProvider>
			);

			setStatus('waiting');
		});
	};

	return {
		confirmDialog,
		status,
		setStatus,
		isWaitingConfirmation,
	};
};
