var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ZYbxGFBRxgoD3clXsgfyV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getRuntimeEnv from '@utils/env';
Sentry.init({
	dsn: getRuntimeEnv('SENTRY_DSN', ''),
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: process.env.APP_ENV || 'development',
	integrations: [Sentry.browserProfilingIntegration(),Sentry.browserTracingIntegration()],
	tracesSampleRate: getRuntimeEnv('APP_ENV', '') === 'production' ? 0.2 : 0.5,
	profilesSampleRate: getRuntimeEnv('APP_ENV', '') === 'production' ? 0.2 : 0.5,
});
