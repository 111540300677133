import { useRouter } from 'next/router';

import { Slash } from '@ctlyst.id/internal-icon';
import { Button, Card, Text } from '@ctlyst.id/internal-ui';

interface NotFoundProps {
	onBack?: () => void;
}

const NotFound = ({ onBack }: NotFoundProps) => {
	const { back } = useRouter();

	const handleBack = () => {
		if (onBack) {
			onBack();
		} else {
			back();
		}
	};

	return (
		<Card p="8" display="flex" justifyContent="center" alignItems="center">
			<Slash size={100} />
			<Text my="4" color="black.medium" w="452px" textAlign="center">
				Halaman yang dituju tidak ditemukan. Silahkan pilih menu lainnya atau
				klik tombol kembali.
			</Text>
			<Button
				data-test-id="CT_component_not-found_back-button"
				onClick={handleBack}
			>
				Kembali
			</Button>
		</Card>
	);
};

export default NotFound;
